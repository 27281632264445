const axios = require('axios').default;

const api = "https://api.forello-expo.com/public/api";

export function getApiHome (){
    const url = api + "/api/home";
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
        })
        .then((response) => response.data)
        .catch((error) => console.error(error))
}

export function getApiProjects (){
    const url = api + "/projects";
    return axios({
          method: 'get',
          url: url,
          mode: 'no-cors',
        })
          .then((response) => response.data)
          .catch((error) => console.error(error))
}

export function getApiSingleProject (id){
    const url = api + "/api/projects/" + id;
    return axios({
          method: 'get',
          url: url,
          mode: 'no-cors',
        })
          .then((response) => response.data)
          .catch((error) => console.error(error))
}

export function getApiBlogs (){
    const url = api + "/blogs";
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
      })
        .then((response) => response.data)
        .catch((error) => console.error(error))
}

export function getApiAbout(){
    const url = api + "/about";
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
    })
        .then((response) => response.data)
        .catch((error) => console.error(error))
}

export function getApiSkills(){
    const url = api + "/skills";
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
    })
    .then((response) => response.data)
    .catch((error) => console.error(error))
}

// ----------------------

export function getCategoriesFromApi (){
    const url = api+"/categories";

    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
}
export function getCompaniesFromApi (page, category, keyword, localisation){
    const url = api+"/companies/"+page+"/"+category+"/"+keyword+"/"+localisation;
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
    })
        .then((response) => response.data)
        .catch((error) => console.error(error))
}
export function getSingleCompanyFromApi (id){
    const url = api+"/company/"+id;
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
      })
        .then((response) => response.data[0])
        .catch((error) => console.error(error))
}

export function getArticlesFromApi (page, categorie){
    const url = api+"/articles/"+page+"/"+categorie;
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
      })
        .then((response) => response.data)
        .catch((error) => console.error(error))
  }

export function getSingleArticleFromApi (id){
const url = api+"/article/"+id;
return axios({
    method: 'get',
    url: url,
    mode: 'no-cors',
    })
    .then((response) => response.data[0])
    .catch((error) => console.error(error))
}

export function getLocalisationsFromApi (){
    const url = api+"api/localisations";
  
    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function getAttendeeFromApi (){
    const url = api+"/listInvitation";
  
    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function postAttendeeFromApi (id){
    const url = api+"/sendInvitation";

    var data = new FormData();
    data.append("id", id);

    return axios({
            method: 'post',
            url: url,
            mode: 'no-cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            data: data,
            redirect: 'follow'
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function getPreviewAttendeeFromApi (id){
    const url = api+"/previewInvitation/"+id;
  
    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function getAlbumsFromApi (){
    const url = api+"/albums";
  
    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function getPhotosFromApi (event, eventtype){
    const url = api+"/photos/"+event+"/"+eventtype;
  
    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function getBlogsFromApi (input){
    const url = api+"/articles/"+input;
  
    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function getBlogFromApi (id){
    const url = api+"/article/"+id;
  
    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function getEventFromApi (event){
    const url = api+"/event/"+event;
  
    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function getPartnerFromApi (){
    const url = api+"/partner";
  
    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function getDocFromApi (){
    const url = api+"/download";
  
    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
  }

  export function postApiContact(request) {
    const url = api+"/createContact";

    var data = new FormData();

    data.append("firstname", request.firstname);
    data.append("lastname", request.lastname);
    data.append("phone", request.phone);
    data.append("email", request.email);
    data.append("choice", request.choice);

    var init = {
        method: 'POST',
        url: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        mode: 'no-cors',
        data: data,
        redirect: 'follow'
    }
    return axios(init)
        .then((response) => response.data)
        .catch((error) => console.error(error))
}